import React, { useEffect } from 'react'
import { Repeater, Text, types, useAdminContext, usePageValues } from 'react-bricks/frontend'
import ColorPalette from '../../components/ColorPalette'
import ColorPaletteItem from '../../components/ColorPaletteItem'
import Heading from '../../components/Heading'
import { theme } from '../../global/_theme'
import Column from '../../layouts/Column'
import Row from '../../layouts/Row'
import Section from '../../layouts/Section'
import Spacer from '../../layouts/Spacer'
import { hexRegEx } from '../../utils/hexRegEx'
import './styles.scss'

/**
 * @type types.Brick<any>
 */
const CaseHeader = ({ backgroundColor, accentColor }) => {
  const { isAdmin } = useAdminContext()
  const [page, setPage] = usePageValues()

  const backgroundColorOk = hexRegEx.test(backgroundColor) ? backgroundColor : theme.primary.base
  const accentColorOk = hexRegEx.test(accentColor) ? accentColor : theme.secondary.base
  useEffect(() => {
    // create page values initially
    if (isAdmin && (!page.customValues.backgroundColor || !page.customValues.accentColor)) {
      setPage({
        customValues: {
          ...page.customValues,
          backgroundColor: backgroundColorOk,
          accentColor: accentColorOk
        }
      })
    }
  }, [page])

  useEffect(() => {
    // update values
    if (isAdmin) {
      setPage({
        customValues: {
          ...page.customValues,
          backgroundColor: backgroundColorOk,
          accentColor: accentColorOk
        }
      })
    }
  }, [backgroundColorOk, accentColorOk])

  useEffect(() => {
    if (isAdmin) {
      const previewPage = document
        .querySelector('#rb-admin-frame')
        ?.contentWindow.document.querySelector('#rb-content-frame')
        ?.contentWindow.document.querySelector('.react-bricks-content')
      previewPage?.setAttribute('style', `background-color: ${backgroundColorOk}`)
    }
  })

  return (
    <>
      <Spacer size="xxl" />
      <Section distance="default">
        <Row width="content">
          <Column>
            <Heading level={1} invert center>
              <Text propName="headline" placeholder="Headline..." renderBlock={props => <>{props.children}</>} />
            </Heading>
            <Spacer size="xxs" when={{ md: 'l' }} />
            <ColorPalette>
              <ColorPaletteItem color={backgroundColorOk} />
              <ColorPaletteItem color={accentColorOk} />
              <Repeater propName="colorPalette" />
            </ColorPalette>
            <Spacer size="l" when={{ md: 'xl', lg: 'h' }} />
          </Column>
        </Row>
      </Section>
    </>
  )
}

CaseHeader.schema = {
  name: 'caseHeader',
  label: '[Header]',
  getDefaultProps: () => ({
    headline: 'Headline',
    backgroundColor: theme.primary.base,
    accentColor: theme.secondary.base
  }),
  sideEditProps: [
    {
      name: 'backgroundColor',
      label: 'Designfarbe 1 (Hintergrund)',
      type: types.SideEditPropType.Text,
      validate: value => (value && hexRegEx.test(value) ? true : 'Kein valider Hex code! Beispiel: #ff00cc oder #a5c')
    },
    {
      name: 'accentColor',
      label: 'Designfarbe 2 (Akzent)',
      type: types.SideEditPropType.Text,
      validate: value => (value && hexRegEx.test(value) ? true : 'Kein valider Hex code! Beispiel: #ff00cc oder #a5c')
    }
  ],
  repeaterItems: [
    {
      name: 'colorPalette',
      itemType: 'color',
      itemLabel: 'Color'
    }
  ]
}

export default CaseHeader
