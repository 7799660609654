import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import './styles.scss'

const Heading = ({ children, level, light, invert, shadow, center, centerWhen, animateIn, delay }) => {
  const classes = cx({
    heading: true,
    'heading--light': light,
    'heading--invert': invert,
    'heading--shadow': shadow,
    'heading--animate': animateIn,
    'heading--center': center,
    'heading→sm--center': centerWhen?.sm,
    'heading→md--center': centerWhen?.md,
    'heading→lg--center': centerWhen?.lg,
    'heading→hg--center': centerWhen?.hg,
    'heading→gt--center': centerWhen?.gt
  })

  const styles = {
    '--delay': delay + 's'
  }

  switch (level) {
    case 1:
      return (
        <h1 className={classes} style={styles}>
          {children}
        </h1>
      )
    case 2:
      return (
        <h2 className={classes} style={styles}>
          {children}
        </h2>
      )
    case 3:
      return (
        <h3 className={classes} style={styles}>
          {children}
        </h3>
      )
    case 4:
      return (
        <h4 className={classes} style={styles}>
          {children}
        </h4>
      )
    case 5:
      return (
        <h5 className={classes} style={styles}>
          {children}
        </h5>
      )
    case 6:
      return (
        <h6 className={classes} style={styles}>
          {children}
        </h6>
      )
    default:
      return (
        <h2 className={classes} style={styles}>
          {children}
        </h2>
      )
  }
}

Heading.propTypes = {
  children: PropTypes.node.isRequired,
  level: PropTypes.number.isRequired,
  light: PropTypes.bool,
  invert: PropTypes.bool,
  center: PropTypes.bool,
  centerWhen: PropTypes.object,
  animateIn: PropTypes.bool,
  delay: PropTypes.number,
  shadow: PropTypes.bool
}

Heading.defaultProps = {
  light: false,
  invert: false,
  shadow: false,
  center: false,
  centerWhen: {},
  animateIn: false,
  delay: 0.1
}

export default Heading
