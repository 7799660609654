import React, { useEffect } from 'react'

const useInterval = (callback, delay) => {
  const savedCallback = React.useRef()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    let id

    const tick = () => {
      savedCallback.current()
    }

    if (delay !== null) {
      id = setInterval(tick, delay)
      return () => clearInterval(id)
    }

    return () => clearInterval(id)
  }, [delay])
}

export default useInterval
