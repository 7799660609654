import React from 'react'
import { RichText, types, usePageValues } from 'react-bricks/frontend'
import Prolog from '../components/Prolog'
import Column from '../layouts/Column'
import Row from '../layouts/Row'
import Section from '../layouts/Section'

/**
 * @type types.Brick<any>
 */
const PrologContent = ({}) => {
  const [page] = usePageValues()
  const rowWidth = page.type === 'blogpost' || page.type === 'job' ? 'narrow' : 'content'

  return (
    <Section mobilePadding={page.type === 'job' ? false : true}>
      <Row width={rowWidth} flex={false}>
        <Column>
          <Prolog invert={page.type === 'case'}>
            <RichText
              placeholder="HighlightText..."
              propName="prolog"
              renderBlock={props => <>{props.children}</>}
              softLineBreak={false}
              multiline={false}
              allowedFeatures={[types.RichTextFeatures.Italic]}
            />
          </Prolog>
        </Column>
      </Row>
    </Section>
  )
}

PrologContent.schema = {
  name: 'prologContent',
  label: 'Prolog / Highlight Text',
  getDefaultProps: () => ({
    prolog: 'Highlight Text...'
  })
}

export default PrologContent
