import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import './styles.scss'

const Position = ({
  variant,
  children,
  width,
  widthWhen,
  height,
  heightWhen,
  top,
  topWhen,
  right,
  rightWhen,
  bottom,
  bottomWhen,
  left,
  leftWhen,
  fullHeight
}) => (
  <div
    className={cx({
      position: true,
      [`position--${variant}`]: variant,
      [`position--fullHeight`]: fullHeight,
      'width-sm': widthWhen?.sm,
      'width-md': widthWhen?.md,
      'width-lg': widthWhen?.lg,
      'width-hg': widthWhen?.hg,
      'height-sm': heightWhen?.sm,
      'height-md': heightWhen?.md,
      'height-lg': heightWhen?.lg,
      'height-hg': heightWhen?.hg,
      top: top,
      'top-sm': topWhen?.sm,
      'top-md': topWhen?.md,
      'top-lg': topWhen?.lg,
      'top-hg': topWhen?.hg,
      right: right,
      'right-sm': rightWhen?.sm,
      'right-md': rightWhen?.md,
      'right-lg': rightWhen?.lg,
      'right-hg': rightWhen?.hg,
      bottom: bottom,
      'bottom-sm': bottomWhen?.sm,
      'bottom-md': bottomWhen?.md,
      'bottom-lg': bottomWhen?.lg,
      'bottom-hg': bottomWhen?.hg,
      left: left,
      'left-sm': leftWhen?.sm,
      'left-md': leftWhen?.md,
      'left-lg': leftWhen?.lg,
      'left-hg': leftWhen?.hg
    })}
    style={{
      '--width': width,
      '--width-sm': widthWhen?.sm,
      '--width-md': widthWhen?.md,
      '--width-lg': widthWhen?.lg,
      '--width-hg': widthWhen?.hg,
      '--height': height,
      '--height-sm': heightWhen?.sm,
      '--height-md': heightWhen?.md,
      '--height-lg': heightWhen?.lg,
      '--height-hg': heightWhen?.hg,
      '--top': top,
      '--top-sm': topWhen?.sm,
      '--top-md': topWhen?.md,
      '--top-lg': topWhen?.lg,
      '--top-hg': topWhen?.hg,
      '--right': right,
      '--right-sm': rightWhen?.sm,
      '--right-md': rightWhen?.md,
      '--right-lg': rightWhen?.lg,
      '--right-hg': rightWhen?.hg,
      '--bottom': bottom,
      '--bottom-sm': bottomWhen?.sm,
      '--bottom-md': bottomWhen?.md,
      '--bottom-lg': bottomWhen?.lg,
      '--bottom-hg': bottomWhen?.hg,
      '--left': left,
      '--left-sm': leftWhen?.sm,
      '--left-md': leftWhen?.md,
      '--left-lg': leftWhen?.lg,
      '--left-hg': leftWhen?.hg
    }}
  >
    {children}
  </div>
)

Position.propTypes = {
  variant: PropTypes.oneOf(['relative', 'absolute']),
  children: PropTypes.node,
  width: PropTypes.string,
  widthWhen: PropTypes.object,
  height: PropTypes.string,
  heightWhen: PropTypes.object,
  top: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([0])]),
  topWhen: PropTypes.object,
  right: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([0])]),
  rightWhen: PropTypes.object,
  bottom: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([0])]),
  bottomWhen: PropTypes.object,
  left: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([0])]),
  leftWhen: PropTypes.object,
  fullHeight: PropTypes.bool
}

Position.defaultProps = {
  variant: 'absolute',
  fullHeight: false
}

export default Position
