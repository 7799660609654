import { navigate } from 'gatsby'
import { types } from 'react-bricks/frontend'
import bricks from '../ui/bricks'
import GatsbyLink from './GatsbyLink'
import pageTypes from './pageTypes'

/**
 * @type types.ReactBricksConfig
 */
const config = {
  appId: process.env.GATSBY_BRICKS_APP_ID,
  apiKey: process.env.BRICKS_API_KEY,
  pageTypes,
  bricks,
  logo: '/images/neoskop-logo.svg',
  renderLocalLink: GatsbyLink,
  navigate,
  loginPath: '/admin',
  editorPath: '/admin/editor',
  playgroundPath: '/admin/playground',
  appSettingsPath: '/admin/app-settings',
  useCssInJs: false,
  appRootElement: '#___gatsby',
  clickToEditSide: types.ClickToEditSide.BottomRight,
  customFields: [],
  enableAutoSave: true,
  disableSaveIfInvalidProps: true,
  enablePreview: true,
  previewPath: '/preview'
}

export default config
