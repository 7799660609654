import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import './styles.scss'

const Section = ({ id, children, distance, distanceDirection = 'top', fullWidth, mobilePadding }) => (
  <section
    className={cx({
      section: true,
      'section--fullWidth': fullWidth,
      'section--noMobilePadding': mobilePadding,
      [`section--distance-${distance}`]: distance,
      [`section--distance-direction-top`]: distanceDirection === 'top' || distanceDirection === 'both',
      [`section--distance-direction-bottom`]: distanceDirection === 'bottom' || distanceDirection === 'both'
    })}
    id={id}
  >
    {children}
  </section>
)

Section.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
  distance: PropTypes.oneOf(['default', 'tiny', 'small', 'large', 'huge']),
  distanceDirection: PropTypes.oneOf(['top', 'bottom', 'both']),
  fullWidth: PropTypes.bool,
  mobilePadding: PropTypes.bool
}

Section.defaultProps = {
  mobilePadding: true
}

export default Section
