import { types } from 'react-bricks/frontend'
import AmazingNumbersContent from './AmazingNumbersContent'
import AmazingNumbersItem from './AmazingNumbersItem'
import ImageWithTextContent from './blog-post-items/ImageWithTextContent'
import InterviewContent from './blog-post-items/InterviewContent'
import PostHeader from './blog-post-items/PostHeader'
import CaseHeader from './case-items/CaseHeader'
import ColorItem from './case-items/ColorItem'
import IconGallery from './case-items/IconGallery'
import IconGalleryItem from './case-items/IconGalleryItem'
import MockupSliderImac from './case-items/MockupSliderImac'
import MockupSliderIpad from './case-items/MockupSliderIpad'
import MockupSliderIphone from './case-items/MockupSliderIphone'
import MockupSliderMacbook from './case-items/MockupSliderMacbook'
import Service from './case-items/Service'
import ServicesAndTechnologies from './case-items/ServicesAndTechnologies'
import Technology from './case-items/Technology'
import ImageContent from './ImageContent'
import BenefitsGallery from './job-items/BenefitsGallery'
import BenefitsItem from './job-items/BenefitsItem'
import Job from './job-items/Job'
import PrologContent from './PrologContent'
import QuoteContent from './QuoteContent'
import RichtextContent from './RichtextContent'

/**
 * @type types.Brick<any>[]
 */
const bricks = [
  PostHeader,
  CaseHeader,
  PrologContent,
  InterviewContent,
  Job,
  BenefitsGallery,
  BenefitsItem,
  ColorItem,
  RichtextContent,
  ImageContent,
  QuoteContent,
  AmazingNumbersContent,
  AmazingNumbersItem,
  MockupSliderIpad,
  MockupSliderMacbook,
  MockupSliderIphone,
  MockupSliderImac,
  IconGallery,
  IconGalleryItem,
  ServicesAndTechnologies,
  Service,
  Technology,
  ImageWithTextContent
]

export default bricks
