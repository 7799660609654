import React from 'react'
import { Text, types } from 'react-bricks/frontend'
import AmazingNumber from '../components/AmazingNumber'

/**
 * @type types.Brick<any>
 */
const AmazingNumbersItem = ({}) => {
  return (
    <AmazingNumber
      unit={<Text propName="unit" placeholder="Einheit..." renderBlock={props => <>{props.children}</>} />}
    >
      <Text propName="value" placeholder="Zahl..." renderBlock={props => <>{props.children}</>} />
    </AmazingNumber>
  )
}

AmazingNumbersItem.schema = {
  name: 'amazingNumbersItem',
  label: 'Staunezahl',
  hideFromAddMenu: true,
  getDefaultProps: () => ({
    unit: 'Einheit',
    value: 'Zahl'
  })
}

export default AmazingNumbersItem
