import React from 'react'
import { Image, RichText, types, usePageValues } from 'react-bricks/frontend'
import Stage from '../../components/Stage'
import '../../global/global.scss'
import Column from '../../layouts/Column'
import Row from '../../layouts/Row'
import Section from '../../layouts/Section'
import Spacer from '../../layouts/Spacer'

/**
 * @type types.Brick<any>
 */
const PostHeader = ({ headline, subheadline }) => {
  const [page] = usePageValues()

  let authorName = `${page.author.firstName} ${page.author.lastName}`
  let authorImage = <img src={page.author.avatarUrl} className="fit-image" alt={authorName} />
  if (!page.customValues?.useAccountDetails) {
    authorName = page.customValues?.authorName ?? 'please_enter_page_attributes'
    authorImage = (
      <img
        src={page.customValues?.authorImage?.src ?? ''}
        className="fit-image"
        alt={page.customValues?.authorImage?.alt ?? authorName}
        srcSet={page.customValues?.authorImage?.srcSet ?? ''}
      />
    )
  }
  return (
    <>
      <Stage
        headline={
          <RichText
            propName="headline"
            placeholder="Type a headline..."
            renderBlock={props => <>{props.children}</>}
            allowedFeatures={[types.RichTextFeatures.Italic]}
            multiline={false}
          />
        }
        subheadline={
          <RichText
            propName="subheadline"
            placeholder="Type a subheadline..."
            renderBlock={props => <>{props.children}</>}
            allowedFeatures={[types.RichTextFeatures.Italic]}
            multiline={false}
          />
        }
        author={{
          name: authorName,
          imageComponent: authorImage
        }}
        reduced
      />

      <Section mobilePadding={false}>
        <Row width="large" noMobilePadding>
          <Column gutter={false} gutterWhen={{ sm: false, md: true, lg: true }}>
            <Image
              propName="headerImage"
              alt={`${subheadline?.[0]?.children?.[0]?.text}: ${headline?.[0]?.children?.[0]?.text}`}
              imageClassName="content-image"
              maxWidth={1304}
              aspectRatio={1.7}
            />
            <Spacer size="l" when={{ md: 'xl', lg: 'h' }} />
          </Column>
        </Row>
      </Section>
    </>
  )
}

PostHeader.schema = {
  name: 'postHeader',
  label: '[Header]',
  getDefaultProps: () => ({
    headline: 'Headline',
    subheadline: 'Subheadline'
  })
}

export default PostHeader
