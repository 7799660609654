import React from 'react'
import { Text, types, usePageValues } from 'react-bricks/frontend'
import Blockquote from '../components/Blockquote'
import { theme } from '../global/_theme'
import Column from '../layouts/Column'
import Row from '../layouts/Row'
import Section from '../layouts/Section'

/**
 * @type types.Brick<any>
 */
const QuoteContent = ({ hideAuthor }) => {
  const [page] = usePageValues()

  return (
    <Section>
      <Row width={page.type === 'blogpost' ? 'narrow' : 'content'} flex={false}>
        <Column>
          <Blockquote
            author={
              !hideAuthor && (
                <Text propName="author" placeholder="Author name..." renderBlock={props => <>{props.children}</>} />
              )
            }
            authorPosition={
              <Text
                propName="authorPosition"
                placeholder="Author position..."
                renderBlock={props => <>{props.children}</>}
              />
            }
            customColor={page.type === 'blogpost' ? theme.secondary.base : page.customValues?.accentColor}
          >
            <Text propName="quote" placeholder="Quote..." renderBlock={props => <>{props.children}</>} />
          </Blockquote>
        </Column>
      </Row>
    </Section>
  )
}

QuoteContent.schema = {
  name: 'quoteContent',
  label: 'Quote',
  getDefaultProps: () => ({
    author: 'Full Name',
    authorPosition: 'Position',
    quote: 'Quote'
  }),
  sideEditProps: [
    {
      name: 'hideAuthor',
      label: 'Autor:in ausblenden',
      type: types.SideEditPropType.Boolean
    }
  ]
}

export default QuoteContent
