import React from 'react'
import { Image, Text, types } from 'react-bricks/frontend'
import SplitBox from '../../components/SplitBox'
import Stage from '../../components/Stage'
import ParallaxEffect from '../../effects/ParallaxEffect'
import '../../global/global.scss'
import CoffeeBean from '../../illustrations/CoffeeBean'
import CoffeeCup from '../../illustrations/CoffeeCup'
import Nerfgun from '../../illustrations/Nerfgun'
import Position from '../../layouts/Position'
import Section from '../../layouts/Section'

/**
 * @type types.Brick<any>
 */
const Job = ({ title }) => (
  <>
    <Stage
      subheadline={
        <Text propName="preTitle" placeholder="Pre-Headline..." renderBlock={props => <>{props.children}</>} />
      }
      headline={<Text propName="title" placeholder="Job Title..." renderBlock={props => <>{props.children}</>} />}
      indicatorLabel="Jetzt bewerben"
      reduced
    >
      <Position top="72%" topWhen={{ md: '36vh' }} left="8vw" leftWhen={{ md: '9vw' }}>
        <Nerfgun />
      </Position>
      <Position right="16vw" rightWhen={{ md: '12vw' }} bottom="72%" bottomWhen={{ md: '18vh' }}>
        <ParallaxEffect speed={1}>
          <CoffeeCup />
        </ParallaxEffect>
      </Position>
      <Position right="64%" rightWhen={{ md: '8vw' }} top="28%" topWhen={{ md: '36vh' }}>
        <CoffeeBean />
      </Position>
      <Position right="16vw" rightWhen={{ md: '32vw' }} bottom="5vh" bottomWhen={{ md: '0vh' }}>
        <CoffeeBean />
      </Position>
    </Stage>
    <Section mobilePadding={false}>
      <SplitBox
        subtitle="Darum geht's"
        title={
          <Text propName="catchphrase" placeholder="Catchphrase..." renderBlock={props => <>{props.children}</>} />
        }
        image={<Image propName="introImage" alt={title} imageClassName="fit-image" maxWidth={668} aspectRatio={1.5} />}
      >
        <Text propName="intro" placeholder="Introduction..." renderBlock={props => <p>{props.children}</p>} />
      </SplitBox>
    </Section>
  </>
)

Job.schema = {
  name: 'job',
  label: '[Job Page Header]',
  getDefaultProps: () => ({
    preTitle: 'Werde Teil des Teams als',
    title: 'Job-Titel (m/w/d)',
    catchphrase: 'Catchphrase',
    intro: 'Beschreibung'
  })
}

export default Job
