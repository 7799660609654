import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import './styles.scss'

const Spacer = ({ children, size, when, display }) => (
  <span
    className={cx({
      spacer: true,
      [`spacer--size-${size}`]: size,
      [`spacer→sm--size-${when?.sm}`]: when?.sm,
      [`spacer→md--size-${when?.md}`]: when?.md,
      [`spacer→lg--size-${when?.lg}`]: when?.lg,
      [`spacer→hg--size-${when?.hg}`]: when?.hg,
      [`spacer→gt--size-${when?.gt}`]: when?.gt
    })}
    style={{ '--display': display }}
  >
    {children}
  </span>
)

Spacer.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(['z', 'xxt', 'xt', 't', 'xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl', 'h', 'xh', 'xxh', 'g']),
  when: PropTypes.object,
  display: PropTypes.oneOf(['inline-block', 'block'])
}

Spacer.defaultProps = {
  size: 'm',
  display: 'block',
  when: {}
}

export default Spacer
