import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import BulletPoint from '../BulletPoint'
import './styles.scss'

const BulletPointList = ({ children, textItems, variant, color, customColor, invert, columns, withColorText }) => (
  <ul
    className={cx({
      'bulletpoint-list': true,
      [`bulletpoint-list--${variant}`]: variant,
      [`bulletpoint-list--${color}`]: color,
      'bulletpoint-list--colorText': withColorText,
      'bulletpoint-list--invert': invert && !withColorText,
      'bulletpoint-list--customColor': customColor
    })}
    style={{ '--width': `${100 / columns}%`, '--customColor': customColor }}
  >
    {textItems &&
      textItems.map((item, index) => (
        <BulletPoint key={'bulletPoint-' + index} variant={variant} color={color} customColor={customColor}>
          {item}
        </BulletPoint>
      ))}
    {children}
  </ul>
)

BulletPointList.propTypes = {
  textItems: PropTypes.arrayOf(PropTypes.string),
  columns: PropTypes.number,
  variant: PropTypes.oneOf(['normal', 'hero']),
  color: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quaternary']),
  customColor: PropTypes.string,
  invert: PropTypes.bool,
  withColorText: PropTypes.bool
}

BulletPointList.defaultProps = {
  columns: 1,
  withColorText: false,
  withFrame: false,
  variant: 'normal'
}

export default BulletPointList
