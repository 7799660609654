import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import ParallaxEffect from '../../effects/ParallaxEffect'
import Spacer from '../../layouts/Spacer'
import AuthorBadge from '../AuthorBadge'
import Heading from '../Heading'
import ScrollIndicator from '../ScrollIndicator'
import './styles.scss'

const Stage = ({
  children,
  headline,
  subheadline,
  author,
  indicatorLabel,
  reduced,
  compactHeadline,
  hideOverflow,
  backgroundImage,
  invert
}) => {
  return (
    <div
      className={cx({
        stage: true,
        'stage--reduced': reduced,
        'stage--invert': invert,
        'with-overflowHidden': hideOverflow
      })}
      style={backgroundImage && { backgroundImage: `url(${backgroundImage})` }}
    >
      {children}
      <div
        className={cx({
          stage__headline: true,
          'stage__headline--compact': compactHeadline,
          'stage__headline--withAuthor': author
        })}
      >
        <ParallaxEffect speed={0.3} enableMobile={false}>
          {subheadline && (
            <ParallaxEffect speed={0.2}>
              <Heading level={3} light center animateIn delay={0.1} invert={invert} shadow={backgroundImage}>
                {subheadline}
              </Heading>
            </ParallaxEffect>
          )}
          <Spacer size="t" when={{ md: 'xxs', gt: 's' }} />
          <Heading level={1} center animateIn delay={0.2} invert={invert} shadow={backgroundImage}>
            {headline}
          </Heading>
        </ParallaxEffect>
        {author && (
          <div className="stage__author">
            <AuthorBadge name={author.name} image={author.imageComponent} />
          </div>
        )}
      </div>
      {/* <StyledCookieLayerContainer>
        <CookieLayer />
      </StyledCookieLayerContainer> */}
      {indicatorLabel && (
        <div className="stage__indicator">
          <ScrollIndicator label={indicatorLabel} invert={invert} />
        </div>
      )}
    </div>
  )
}

Stage.propTypes = {
  children: PropTypes.node,
  headline: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  subheadline: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  author: PropTypes.object,
  indicatorLabel: PropTypes.string,
  reduced: PropTypes.bool,
  canvas: PropTypes.string,
  compactHeadline: PropTypes.bool,
  hideOverflow: PropTypes.bool,
  backgroundImage: PropTypes.string,
  invert: PropTypes.bool
}

Stage.defaultProps = {
  hideFireworkOnMobile: false,
  reduced: false,
  magnifyCanvasOnMobile: true,
  hideOverflow: false
}

export default Stage
