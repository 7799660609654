import PropTypes from 'prop-types'
import React from 'react'
import Spacer from '../../layouts/Spacer'
import Icon from '../Icon'
import './styles.scss'

const BulletPoint = ({ children, variant, color, customColor, ...rest }) => (
  <li className="bullet-point" {...rest}>
    <Icon
      name="check"
      fill={color}
      customFill={customColor}
      size={variant === 'hero' ? 22 : 20}
      sizeWhen={{ md: variant === 'hero' ? 24 : 22 }}
    />
    <Spacer size="s" display="inline-block" />
    {children}
  </li>
)

BulletPoint.propTypes = {
  variant: PropTypes.oneOf(['normal', 'hero']),
  color: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quaternary']),
  customColor: PropTypes.string
}

BulletPoint.defaultProps = {
  variant: 'normal'
}

export default BulletPoint
