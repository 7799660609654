import cx from 'classnames'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import React from 'react'
import './styles.scss'

const Blockquote = ({
  children,
  author,
  image,
  authorPosition,
  color,
  customColor,
  size,
  indent,
  withOpenQuote,
  margin
}) => (
  <div
    className={cx({
      blockquote: true,
      'blockquote--flex': image,
      'blockquote--withMargin': margin,
      [`blockquote__inner--${size}`]: size,
      'blockquote--indend': indent,
      'blockquote--withOpenQuote': withOpenQuote
    })}
  >
    {image && (
      <div className="blockquote__image">
        <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt={'Zitat von ' + author} />
      </div>
    )}
    <div className="blockquote__inner" style={{ '--color': customColor ? customColor : color }}>
      {children}
      {author && (
        <div className="blockquote__author" style={{ '--color': customColor ? customColor : color }}>
          {author}
          {authorPosition && (
            <div className="blockquote__author__position" style={{ '--color': customColor ? customColor : color }}>
              {authorPosition}
            </div>
          )}
        </div>
      )}
    </div>
  </div>
)

Blockquote.propTypes = {
  children: PropTypes.node,
  author: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool]),
  image: PropTypes.object,
  authorPosition: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf(['primary-base', 'secondary-base', 'neutral-white'])]),
  customColor: PropTypes.string,
  size: PropTypes.oneOf(['default', 'large']),
  indent: PropTypes.bool,
  withOpenQuote: PropTypes.bool,
  margin: PropTypes.bool
}

Blockquote.defaultProps = {
  color: 'secondary-base',
  size: 'large',
  indent: false,
  withOpenQuote: false,
  margin: true
}

export default Blockquote
