import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import './styles.scss'

const Row = ({ children, flex, align, justify, id, width, noMobilePadding, overflowHidden }) => (
  <div
    className={cx({
      row: true,
      [`row--${width}`]: width,
      'row--flex': flex,
      [`row--align-${align}`]: align,
      [`row--justify-${justify}`]: justify,
      [`row--noMobilePadding`]: noMobilePadding,
      [`row--overflowHidden`]: overflowHidden
    })}
    id={id}
  >
    {children}
  </div>
)

Row.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
  width: PropTypes.oneOf(['default', 'large', 'medium', 'content', 'narrow', 'fluid']),
  flex: PropTypes.bool,
  align: PropTypes.string,
  justify: PropTypes.string,
  noMobilePadding: PropTypes.bool,
  overflowHidden: PropTypes.bool
}

Row.defaultProps = {
  width: 'default',
  flex: true,
  align: null,
  justify: null,
  noMobilePadding: false,
  overflowHidden: false
}

export default Row
