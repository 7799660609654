import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import React from 'react'
import ParallaxEffect from '../../effects/ParallaxEffect'
import Column from '../../layouts/Column'
import Row from '../../layouts/Row'
import Heading from '../Heading'
import './styles.scss'

const SplitBox = ({ children, subtitle, title, image }) => (
  <div className="splitbox" id="splitBox">
    <Row align="stretch" width="large" noMobilePadding>
      <Column span={12} spanWhen={{ lg: 6 }} orderWhen={{ lg: 2 }} gutter={false}>
        <ParallaxEffect speed={0.6} enableMobile={false}>
          <div className="splitbox__image">
            {React.isValidElement(image) ? image : <GatsbyImage image={image} alt={title} />}
          </div>
        </ParallaxEffect>
      </Column>
      <Column span={12} spanWhen={{ lg: 6 }} orderWhen={{ lg: 1 }} gutter={false}>
        <div className="splitbox__content">
          <div className="splitbox__content__subheadline">{subtitle}</div>
          <Heading level={2} invert>
            {title}
          </Heading>
          <div className="splitbox__content__text">{children}</div>
        </div>
      </Column>
    </Row>
  </div>
)

SplitBox.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.element]).isRequired
}

export default SplitBox
