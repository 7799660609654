import React from 'react'
import { Repeater, types } from 'react-bricks/frontend'
import AmazingNumbers from '../components/AmazingNumbers'
import Column from '../layouts/Column'
import Row from '../layouts/Row'
import Section from '../layouts/Section'

/**
 * @type types.Brick<any>
 */
const AmazingNumbersContent = ({}) => {
  return (
    <Section>
      <Row width="narrow">
        <Column>
          <AmazingNumbers>
            <Repeater propName="items" />
          </AmazingNumbers>
        </Column>
      </Row>
    </Section>
  )
}

AmazingNumbersContent.schema = {
  name: 'amazingNumbers',
  label: 'Staunezahlen',
  repeaterItems: [
    {
      name: 'items',
      itemType: 'amazingNumbersItem',
      itemLabel: 'Staunezahl',
      min: 1,
      max: 12
    }
  ]
}

export default AmazingNumbersContent
