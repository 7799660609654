import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import './styles.scss'

const Banner = ({ id, children, variant, padding, paddingWhen, connect, hideOverflow }) => (
  <section
    className={cx({
      banner: true,
      [`banner--${variant}`]: variant,
      'banner--connected': connect,
      'banner--overflowHidden': hideOverflow,
      'banner--padding': padding,
      'banner--padding-sm': paddingWhen.sm,
      'banner--padding-md': paddingWhen.md,
      'banner--padding-lg': paddingWhen.lg,
      'banner--padding-hg': paddingWhen.hg
    })}
    id={id}
  >
    <div className="banner__inner">{children}</div>
  </section>
)

Banner.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['primary', 'light', 'overlay']),
  padding: PropTypes.bool,
  paddingWhen: PropTypes.object,
  connect: PropTypes.bool,
  hideOverflow: PropTypes.bool
}

Banner.defaultProps = {
  variant: 'primary',
  padding: true,
  paddingWhen: {
    sm: true,
    md: true,
    lg: true,
    hg: true
  },
  connect: false,
  hideOverflow: false
}

export default Banner
