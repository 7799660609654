import { AnimatePresence } from 'framer-motion'
import 'normalize.css'
import PropTypes from 'prop-types'
import React from 'react'
import { ReactBricks } from 'react-bricks/frontend'
import MediaContextProvider from '../../../context/MediaContext'
import { PageContextProvider } from '../../../context/PageContext'
import config from '../../../react-bricks/config'
import '../../global/global.scss'
import PageWrapper from '../PageWrapper'

const SiteWrapper = ({ children, location, ...rest }) => {
  // prevent bug on login-page of ReactBricks that somehow occurs when wrapping it with <AnimatePresence>
  const isAdminPage = location.pathname?.startsWith('/admin')

  return (
    <MediaContextProvider>
      <PageContextProvider>
        <PageWrapper>
          <ReactBricks {...config}>
            {isAdminPage ? children : <AnimatePresence mode="wait">{children}</AnimatePresence>}
          </ReactBricks>
        </PageWrapper>
      </PageContextProvider>
    </MediaContextProvider>
  )
}

SiteWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}

export default SiteWrapper
