import PropTypes from 'prop-types'
import React from 'react'
import './styles.scss'

const IconGridItem = ({ text, title, icon, ...rest }) => (
  <div className="icon-grid-item" {...rest}>
    <div className="icon-grid-item__image">{icon}</div>
    <h5 className="icon-grid-item__title">{title}</h5>
    <div className="icon-grid-item__text">{text}</div>
  </div>
)

IconGridItem.propTypes = {
  text: PropTypes.node,
  title: PropTypes.node,
  icon: PropTypes.element
}

export default IconGridItem
