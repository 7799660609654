import PropTypes from 'prop-types'
import React, { useLayoutEffect, useState } from 'react'
import { breakpoints } from '../ui/global/_breakpoints'
import { throttle } from '../ui/utils/throttle'

export const MediaContext = React.createContext(null)
export const Provider = MediaContext.Provider
export const Consumer = MediaContext.Consumer

// const getMatchmedia = breakpoint => window.matchMedia('(min-width: ' + breakpoint + ')')

export const MediaContextProvider = ({ children }) => {
  const [viewportWidth, setViewportWidth] = useState(null)
  const [viewportHeight, setViewportHeight] = useState(null)
  const [breakpoint, setBreakpoint] = useState({ xs: true, sm: true, md: true, lg: false, hg: false })
  const [currentBreakpoint, setCurrentBreakpoint] = useState('hg')

  const handleBreakpoints = () => {
    const xs = window.matchMedia('(min-width: ' + breakpoints.xs + ')')
    const sm = window.matchMedia('(min-width: ' + breakpoints.sm + ')')
    const md = window.matchMedia('(min-width: ' + breakpoints.md + ')')
    const lg = window.matchMedia('(min-width: ' + breakpoints.lg + ')')
    const hg = window.matchMedia('(min-width: ' + breakpoints.hg + ')')

    const mediaObj = {
      xs: xs.matches,
      sm: sm.matches,
      md: md.matches,
      lg: lg.matches,
      hg: hg.matches
    }

    setBreakpoint(mediaObj)

    const breakpointKeys = Object.keys(mediaObj).reverse()
    const breakpointValues = Object.values(mediaObj).reverse()

    for (let breakpoint in breakpointKeys) {
      if (breakpointValues[breakpoint]) {
        setCurrentBreakpoint(breakpointKeys[breakpoint])
        document.documentElement.setAttribute('data-current-breakpoint', breakpointKeys[breakpoint])
        break
      }
    }
  }

  const handleViewportChange = () => {
    setViewportWidth(document.body.clientWidth)
    setViewportHeight(window.innerHeight)
    handleBreakpoints()
  }

  useLayoutEffect(() => {
    setViewportWidth(document.body.clientWidth)
    setViewportHeight(window.innerHeight)
    handleBreakpoints()

    const handleResize = throttle(handleViewportChange, 250)
    window.addEventListener('resize', handleResize, { passive: true })

    return () => window.removeEventListener('resize', handleResize, { passive: true })
  }, [])

  return (
    <Provider
      value={{
        viewportWidth,
        viewportHeight,
        breakpoint,
        currentBreakpoint
      }}
    >
      {children}
    </Provider>
  )
}

MediaContextProvider.propTypes = {
  children: PropTypes.node
}

export default MediaContextProvider
