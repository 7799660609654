import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useLayoutEffect, useState } from 'react'
import './styles.scss'

const PageWrapper = ({ children }) => {
  const [initial, setInitial] = useState(true)

  useLayoutEffect(() => {
    const status = document.querySelector('html').dataset.initialRender
    setInitial(status)
  }, [])

  return (
    <div
      className={cx({
        'page-wrapper': true,
        'page-wrapper--initial': initial
      })}
    >
      {children}
    </div>
  )
}

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired
}

export default PageWrapper
