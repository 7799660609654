import React from 'react'
import { types } from 'react-bricks/frontend'

/**
 * @type types.IPageType[]
 */
const pageTypes = [
  {
    name: 'blogpost',
    pluralName: 'Blogposts',
    allowedBlockTypes: [
      'postHeader',
      'prologContent',
      'richtextContent',
      'imageContent',
      'quoteContent',
      'interviewContent',
      'splitContent',
      'amazingNumbers'
    ],
    defaultLocked: false,
    defaultStatus: types.PageStatus.Draft,
    defaultLanguage: 'de',
    getDefaultContent: () => ['postHeader', 'prologContent', 'richtextContent'],
    customFields: [
      {
        name: 'category',
        type: types.SideEditPropType.Select,
        label: 'Kategorie',
        selectOptions: {
          display: types.OptionsDisplay.Select,
          options: [
            {
              value: 'random',
              label: '<select>'
            },
            {
              value: 'kultur',
              label: '#kultur'
            },
            {
              value: 'hey',
              label: '#hey'
            },
            {
              value: 'dev',
              label: '#dev'
            },
            {
              value: 'recap',
              label: '#recap'
            }
          ]
        }
      },
      {
        groupName: 'Autor:in Angaben',
        defaultOpen: true,
        props: [
          {
            name: 'useAccountDetails',
            type: types.SideEditPropType.Boolean,
            label: 'Name & Bild aus Account übernehmen'
          },
          {
            name: 'authorName',
            type: types.SideEditPropType.Text,
            label: 'Name',
            show: props => !props.useAccountDetails,
            validate: value => (value && value !== '' ? true : 'Der Name ist notwendig für den Header und Footer!')
          },
          {
            name: 'authorImage',
            type: types.SideEditPropType.Image,
            label: 'Bild',
            show: props => !props.useAccountDetails,
            imageOptions: {
              maxWidth: 200,
              aspectRatio: 1
            },
            validate: value => (value && value !== '' ? true : 'Das Bild ist notwendig für den Header und Footer!')
          },
          {
            name: 'authorDescription',
            type: types.SideEditPropType.Text,
            label: 'Beschreibung',
            validate: value =>
              value && value !== '' ? true : 'Eine Autor:in-Beschreibung ist notwendig für den Footer!'
          }
        ]
      }
    ]
  },
  {
    name: 'job',
    pluralName: 'Jobs',
    allowedBlockTypes: ['job', 'richtextContent', 'prologContent', 'benefitsGallery'],
    defaultLocked: false,
    defaultStatus: types.PageStatus.Draft,
    defaultLanguage: 'de',
    getDefaultContent: () => ['job', 'richtextContent', 'benefitsGallery']
  },
  {
    name: 'case',
    pluralName: 'Cases',
    allowedBlockTypes: [
      'caseHeader',
      'color',
      'iphoneSlider',
      'ipadSlider',
      'macbookSlider',
      'imacSlider',
      'servicesAndTechnologies',
      'prologContent',
      'richtextContent',
      'imageContent',
      'quoteContent',
      'iconGallery'
    ],
    defaultLocked: false,
    defaultStatus: types.PageStatus.Draft,
    defaultLanguage: 'de',
    getDefaultContent: () => ['caseHeader', 'servicesAndTechnologies'],
    customFields: [
      {
        name: 'client',
        type: types.SideEditPropType.Text,
        label: 'Kunde/Produkt',
        validate: value => (value && value !== '' ? true : 'Der Kundenname wird benötigt!')
      },
      {
        name: 'domain',
        type: types.SideEditPropType.Text,
        label: 'Produkt Domain',
        validate: value => (value && value !== '' ? true : 'Die Domain wird benötigt!')
      },
      {
        name: 'description',
        type: types.SideEditPropType.Text,
        label: 'Kurzbeschreibung',
        validate: value => (value && value !== '' ? true : 'Der Text wird für die Übersicht der Cases benötigt!')
      },
      {
        name: 'colorInfotext',
        type: types.SideEditPropType.Custom,
        label: 'Info',
        component: () => (
          <p style={{ paddingLeft: '4%', fontSize: '0.8em' }}>
            Die Designfarben können über den Header festgelegt werden
          </p>
        )
      },
      {
        name: 'desktopImage',
        type: types.SideEditPropType.Image,
        label: 'Desktop Screenshot',
        validate: value => (value ? true : 'Das Bild wird für die Übersicht der Cases benötigt!')
      },
      {
        name: 'mobileImage',
        type: types.SideEditPropType.Image,
        label: 'Mobile Screenshot',
        validate: value => (value ? true : 'Das Bild wird für die Übersicht der Cases benötigt!')
      },
      {
        groupName: 'visuals',
        defaultOpen: true,
        props: [
          {
            name: 'visual1',
            type: types.SideEditPropType.Image,
            label: 'Visual 1'
          },
          {
            name: 'visual2',
            type: types.SideEditPropType.Image,
            label: 'Visual 2'
          },
          {
            name: 'visual3',
            type: types.SideEditPropType.Image,
            label: 'Visual 3'
          },
          {
            name: 'visual4',
            type: types.SideEditPropType.Image,
            label: 'Visual 4'
          }
        ]
      }
    ]
  }
]

export default pageTypes
