import React from 'react'
import { Text, types } from 'react-bricks/frontend'
import BenefitsGridItem from '../../components/BenefitsGridItem'
import '../../global/global.scss'

/**
 * @type types.Brick<any>
 */
const BenefitsItem = ({ emoji, title, description, hashtags, renderDescriptionAsHtml, index, ...rest }) => {
  return (
    <BenefitsGridItem
      emoji={<Text propName="emoji" placeholder="🐣" renderBlock={props => <>{props.children}</>} />}
      title={<Text propName="title" placeholder="Titel..." renderBlock={props => <>{props.children}</>} />}
      description={description}
      hashtags={hashtags}
      renderDescriptionAsHtml={renderDescriptionAsHtml}
      index={index}
      {...rest}
    />
  )
}

BenefitsItem.schema = {
  name: 'benefitsItem',
  label: 'Benefit',
  getDefaultProps: () => ({
    emoji: '🐣',
    title: 'Titel',
    description: 'Description'
  }),
  hideFromAddMenu: true,
  sideEditProps: [
    {
      name: 'description',
      label: 'Beschreibung',
      type: types.SideEditPropType.Textarea,
      textareaOptions: {
        height: 250
      }
    },
    {
      name: 'renderDescriptionAsHtml',
      label: 'Beschreibung als HTML-Code rendern (mit Vorsicht nutzen)',
      type: types.SideEditPropType.Boolean
    },
    {
      name: 'hashtags',
      label: 'Hashtags (optional)',
      type: types.SideEditPropType.Text,
      show: props => !props.renderDescriptionAsHtml
    }
  ]
}

export default BenefitsItem
