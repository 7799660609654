import React from 'react'
import { types } from 'react-bricks/frontend'
import ColorPaletteItem from '../../components/ColorPaletteItem'
import { hexRegEx } from '../../utils/hexRegEx'
import './styles.scss'

/**
 * @type types.Brick<any>
 */
const ColorItem = ({ hex, ...rest }) => {
  return (
    <div {...rest} className="color-item-wrapper">
      <ColorPaletteItem color={hexRegEx.test(hex) ? hex : '#000'} />
    </div>
  )
}

ColorItem.schema = {
  name: 'color',
  label: 'Palette Color',
  hideFromAddMenu: true,
  getDefaultProps: () => ({ hex: '#000' }),
  sideEditProps: [
    {
      name: 'hex',
      label: 'Hex Color',
      type: types.SideEditPropType.Text,
      validate: value => (value && hexRegEx.test(value) ? true : 'Kein valider Hex code! Beispiel: #ff00cc oder #a5c')
    }
  ]
}

export default ColorItem
