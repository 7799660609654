import React from 'react'
import { Image, types, usePageValues } from 'react-bricks/frontend'
import Richtext from '../components/Richtext'
import '../global/global.scss'
import Column from '../layouts/Column'
import Row from '../layouts/Row'
import Section from '../layouts/Section'

/**
 * @type types.Brick<any>
 */
const ImageContent = ({}) => {
  const [page] = usePageValues()

  return (
    <Section>
      <Row flex={false} width={page.type === 'blogpost' ? 'narrow' : 'content'}>
        <Column>
          <Richtext>
            <Image
              propName="blogpostImage"
              alt="Veranschaulichendes Bild im Blogeintrag"
              imageClassName="content-image"
              maxWidth={1024}
              aspectRatio={page.type === 'blogpost' ? 1.6 : null}
            />
          </Richtext>
        </Column>
      </Row>
    </Section>
  )
}

ImageContent.schema = {
  name: 'imageContent',
  label: 'Image'
}

export default ImageContent
