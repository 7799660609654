import './styles.scss'

import PropTypes from 'prop-types'
import React from 'react'
import cx from 'classnames'

const Richtext = ({ children, invert, variant }) => (
  <div className={cx({ richtext: true, [`richtext--${variant}`]: variant, 'richtext--invert': invert })}>
    {children}
  </div>
)

Richtext.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(['default', 'small']),
  invert: PropTypes.bool
}

Richtext.defaultProps = {
  invert: false
}

export default Richtext
