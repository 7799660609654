import React from 'react'
import { Repeater, usePageValues } from 'react-bricks/frontend'
import BulletPointList from '../../components/BulletPointList'
import Button from '../../components/Button'
import Heading from '../../components/Heading'
import Banner from '../../layouts/Banner'
import Column from '../../layouts/Column'
import Flex from '../../layouts/Flex'
import Row from '../../layouts/Row'
import Spacer from '../../layouts/Spacer'
import './styles.scss'

/**
 * @type types.Brick<any>
 */
const ServicesAndTechnologies = ({}) => {
  const [page] = usePageValues()

  return (
    <>
      <Banner variant="overlay">
        <Row width="large">
          <Column>
            <Heading level={3} invert center>
              Projektumfang
            </Heading>
          </Column>
          <Spacer size="xs" when={{ md: 'l' }} />
          <Column>
            <BulletPointList invert customColor={page.customValues?.accentColor} columns={3}>
              <Repeater propName="services" />
            </BulletPointList>
          </Column>
        </Row>
      </Banner>
      <Spacer size="xxt" when={{ md: 'xxt' }} />
      <Banner variant="overlay" connect>
        <Row width="large" justifyContent="flex-start">
          <Column>
            <Heading level={3} invert center>
              Technologien im Einsatz
            </Heading>
          </Column>
        </Row>
        <Spacer size="xt" sizeWhen={{ md: 'xs' }} />
        <div className="technology-stack">
          <Row width="large">
            <Repeater propName="technologies" />
          </Row>
        </div>
        <Spacer size="l" when={{ md: 'h' }} />
        <Flex justify="center">
          <Button
            label={`${page.customValues?.domain ?? 'Seite'} besuchen`}
            to={`https://${page.customValues?.domain}`}
            kind="external"
            variant="custom"
            customColor={page.customValues?.accentColor}
          />
        </Flex>
        <Spacer size="m" when={{ md: 'z' }} />
      </Banner>
    </>
  )
}

ServicesAndTechnologies.schema = {
  name: 'servicesAndTechnologies',
  label: '[Projektumfang & Technologien]',
  repeaterItems: [
    {
      name: 'services',
      itemType: 'service',
      itemLabel: 'Leistung',
      min: 1
    },
    {
      name: 'technologies',
      itemType: 'technology',
      itemLabel: 'Technologie',
      min: 1
    }
  ]
}

export default ServicesAndTechnologies
