import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import './styles.scss'

const Flex = ({ children, direction, justify, align, wrap }) => (
  <div
    className={cx({
      flex: true,
      [`flex--direction-${direction}`]: direction,
      [`flex--align-${align}`]: align,
      [`flex--justify-${justify}`]: justify,
      [`flex--wrap-${wrap}`]: wrap,
      [`flex--wrap-${wrap}`]: wrap
    })}
  >
    {children}
  </div>
)

Flex.propTypes = {
  children: PropTypes.node,
  direction: PropTypes.string,
  wrap: PropTypes.string,
  justify: PropTypes.string,
  align: PropTypes.string
}

Flex.defaultProps = {
  direction: 'row',
  justify: 'flex-start',
  align: 'left',
  wrap: 'nowrap',
  gutter: false,
  gutterWhen: {
    sm: false,
    md: false,
    lg: false,
    hg: false
  }
}

export default Flex
