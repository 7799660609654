import cx from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useCallback, useContext, useState } from 'react'
import Close from '../../../assets/icons/close.svg'
import { BenefitsContext } from '../BenefitsGrid/benefits-context'
import './styles.scss'

const BenefitsGridItem = ({ emoji, title, description, hashtags, renderDescriptionAsHtml, index, ...rest }) => {
  const { selected, changeSelected, freeForEntering, setFreeForEntering } = useContext(BenefitsContext)
  const [height, setHeight] = useState(150)

  const measuredRef = useCallback(node => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height)
    }
  }, [])

  const descriptionContent = renderDescriptionAsHtml ? (
    <div ref={measuredRef} className="benefit-description__content" dangerouslySetInnerHTML={{ __html: description }} />
  ) : (
    <div ref={measuredRef} className="benefit-description__content">
      {description}
      {hashtags && hashtags !== '' && <p>{hashtags}</p>}
    </div>
  )

  return (
    <>
      <li className="benefit-container" onClick={() => changeSelected(index)}>
        <div
          className={cx({
            'benefit-cover': true,
            'benefit-cover--select': selected === index
          })}
          {...rest}
        >
          <div className="benefit-cover__emoji">{emoji}</div>
          <h6 className="benefit-cover__title">{title}</h6>
          <div className="benefit-cover__cross">
            <Close />
          </div>
        </div>
      </li>
      <AnimatePresence onExitComplete={() => setFreeForEntering(true)}>
        {selected === index && freeForEntering && (
          <motion.li
            key={`des${index}`}
            initial={{ opacity: 0, height: 0, margin: '0px 0' }}
            animate={{ opacity: 1, height: `${height}px`, margin: '8px 0' }}
            transition={{ duration: 0.4 }}
            exit={{ opacity: 0, height: 0, margin: '0px 0' }}
            className="benefit-description"
          >
            {descriptionContent}
          </motion.li>
        )}
      </AnimatePresence>
    </>
  )
}

export default BenefitsGridItem
