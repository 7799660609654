import React from 'react'
import { types, usePageValues } from 'react-bricks/frontend'
import MockupSlider from '../../components/MockupSlider'
import '../../global/global.scss'
import Column from '../../layouts/Column'
import Row from '../../layouts/Row'
import Section from '../../layouts/Section'

/**
 * @type types.Brick<any>
 */
const MockupSliderMacbook = ({ image1, image2, image3, image4, image5 }) => {
  const [page] = usePageValues()
  return (
    <Section distance="tiny">
      <Row width="content">
        <Column>
          <MockupSlider
            device="macbook"
            interactiveColor={page.customValues?.accentColor}
            screens={[image1, image2, image3, image4, image5]
              .filter(image => image)
              .map(image => (
                <img
                  key={image.src}
                  src={image.src ?? ''}
                  className="fit-image"
                  alt={image.alt ?? ''}
                  srcSet={image.srcSet ?? ''}
                />
              ))}
          />
        </Column>
      </Row>
    </Section>
  )
}

const imageOptions = {
  maxWidth: 1280,
  aspectRatio: 1.555
}

MockupSliderMacbook.schema = {
  name: 'macbookSlider',
  label: 'Mockup Slider - MacBook',
  getDefaultProps: () => ({ count: 3 }),
  sideEditProps: [
    {
      type: types.SideEditPropType.Range,
      name: 'count',
      label: 'Anzahl an Bildern',
      rangeOptions: {
        max: 5,
        min: 1,
        step: 1
      }
    },
    {
      type: types.SideEditPropType.Image,
      name: 'image1',
      imageOptions
    },
    {
      type: types.SideEditPropType.Image,
      name: 'image2',
      imageOptions,
      show: props => !props.count || props.count >= 2
    },
    {
      type: types.SideEditPropType.Image,
      name: 'image3',
      imageOptions,
      show: props => !props.count || props.count >= 3
    },
    {
      type: types.SideEditPropType.Image,
      name: 'image4',
      imageOptions,
      show: props => props.count && props.count >= 4
    },
    {
      type: types.SideEditPropType.Image,
      name: 'image5',
      imageOptions,
      show: props => props.count && props.count >= 5
    }
  ]
}

export default MockupSliderMacbook
