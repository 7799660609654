import PropTypes from 'prop-types'
import React from 'react'
import './styles.scss'

export const AmazingNumber = ({ children, unit, ...rest }) => {
  return (
    <div className="amazing-number" {...rest}>
      <div className="amazing-number__value">{children}</div>
      <div className="amazing-number__unit">{unit}</div>
    </div>
  )
}

AmazingNumber.propTypes = {
  children: PropTypes.node.isRequired,
  unit: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
}

export default AmazingNumber
