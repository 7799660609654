import React from 'react'
import { Image, RichText, types } from 'react-bricks/frontend'
import SplitContent from '../../components/SplitContent'
import '../../global/global.scss'
import { theme } from '../../global/_theme.js'

/**
 * @type types.Brick<any>
 */
const ImageWithTextContent = ({ switchOrder, fixedRatio, background }) => {
  return (
    <SplitContent
      background={background}
      switchOrder={switchOrder}
      fixedRatio={fixedRatio}
      img={<Image propName="image" imageClassName="fit-image" noWrapper maxWidth={668} />}
    >
      <RichText
        propName="richtext"
        placeholder="Richtext..."
        renderBlock={({ children }) => <p>{children}</p>}
        allowedFeatures={[
          types.RichTextFeatures.Link,
          types.RichTextFeatures.UnorderedList,
          types.RichTextFeatures.OrderedList,
          types.RichTextFeatures.Heading3
        ]}
      />
    </SplitContent>
  )
}

ImageWithTextContent.schema = {
  name: 'splitContent',
  label: 'Split Box (Text + Bild)',
  getDefaultProps: () => ({
    richtext: 'Text here...'
  }),
  sideEditProps: [
    {
      name: 'switchOrder',
      label: 'Reihenfolge umkehren',
      type: types.SideEditPropType.Boolean
    },
    {
      name: 'fixedRatio',
      label: 'Festes Seitenverhältnis des Bildes',
      type: types.SideEditPropType.Boolean
    },
    {
      name: 'background',
      label: 'Hintergrundfarbe',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Color,
        options: [
          {
            value: {
              color: theme.neutral.background,
              name: null
            },
            label: 'default'
          },
          {
            value: {
              color: theme.neutral.medium,
              name: 'gray'
            },
            label: 'gray'
          },
          {
            value: {
              color: theme.neutral.white,
              name: 'none'
            },
            label: 'none'
          },
          {
            value: {
              color: theme.primary.base,
              name: 'primary'
            },
            label: 'primary'
          },
          {
            value: {
              color: theme.secondary.base,
              name: 'secondary'
            },
            label: 'secondary'
          },
          {
            value: {
              color: theme.tertiary.base,
              name: 'tertiary'
            },
            label: 'tertiary'
          }
        ]
      }
    }
  ]
}

export default ImageWithTextContent
