import React from 'react'
import { RichText, Text, types } from 'react-bricks/frontend'
import Interview from '../../components/Interview'
import Column from '../../layouts/Column'
import Row from '../../layouts/Row'
import Section from '../../layouts/Section'

/**
 * @type types.Brick<any>
 */
const InterviewContent = ({}) => (
  <Section>
    <Row width="narrow" flex={false}>
      <Column>
        <Interview
          question={<Text propName="question" placeholder="Question..." renderBlock={props => <>{props.children}</>} />}
        >
          <RichText
            propName="answer"
            placeholder="Answer..."
            allowedFeatures={[types.RichTextFeatures.Link]}
            renderBlock={({ children }) => <p>{children}</p>}
          />
        </Interview>
      </Column>
    </Row>
  </Section>
)

InterviewContent.schema = {
  name: 'interviewContent',
  label: 'Interview',
  getDefaultProps: () => ({
    question: 'Question',
    answer: 'Answer'
  }),
  sideEditProps: []
}

export default InterviewContent
