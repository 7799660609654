export const theme = {
  primary: {
    light: '#AF8EBF',
    base: '#643C78',
    dark: '#3C1E46',
    darker: '#2B1432',
    rgb: {
      base: '100, 60, 120'
    }
  },

  secondary: {
    light: '#4FC5FB',
    base: '#50BEF0',
    dark: '#3FACDD'
  },

  tertiary: {
    base: '#FA9676',
    dark: '#D96642'
  },

  quaternary: {
    base: '#FAE664'
  },

  neutral: {
    white: '#ffffff',
    background: '#f7f7f7',
    light: '#E5E5E5',
    medium: '#DEDEDE',
    text: '#A6A6A6',
    black: '#000000'
  },

  duration: {
    fast: '0.3s',
    normal: '0.6s',
    sluggish: '0.9s',
    slow: '3.2s',
    abstract: '60s',
    pageTransition: '0.6s',
    raw: {
      fast: 0.3,
      normal: 0.6,
      sluggish: 0.9,
      slow: 3.2,
      abstract: 60,
      pageTransition: 0.6
    }
  },

  easing: {
    default: 'cubic-bezier(.66, 0, .34, 1)',
    accelerated: 'cubic-bezier(.55,0,.35,1)',
    array: {
      default: [0.66, 0, 0.34, 1],
      accelerated: [0.55, 0, 0.35, 1]
    }
  },

  dm: {
    z: '0px',
    xt: '4px',
    t: '8px',
    xxs: '12px',
    xs: '16px',
    s: '20px',
    m: '24px',
    l: '32px',
    base: '36px',
    xl: '48px',
    xxl: '64px',
    h: '96px',
    xh: '148px',
    xxh: '172px',
    g: '200px'
  }
}
