import React from 'react'
import { Text, types, usePageValues } from 'react-bricks/frontend'
import BulletPoint from '../../components/BulletPoint'

/**
 * @type types.Brick<any>
 */
const Service = ({ text, index, ...rest }) => {
  const [page] = usePageValues()
  return (
    <BulletPoint {...rest} customColor={page.customValues?.accentColor}>
      <Text propName="text" placeholder="Text..." renderBlock={props => <>{props.children}</>} />
    </BulletPoint>
  )
}

Service.schema = {
  name: 'service',
  label: 'Leistung',
  getDefaultProps: () => ({
    text: 'Text'
  }),
  hideFromAddMenu: true
}

export default Service
