import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import './styles.scss'

const Column = ({
  children,
  span,
  spanWhen,
  shift,
  shiftWhen,
  offset,
  offsetWhen,
  order,
  orderWhen,
  gutter,
  gutterWhen
}) => (
  <div
    className={cx({
      column: true,
      'column--gutter': gutter,
      'column--gutter-sm': gutterWhen?.sm,
      'column--gutter-md': gutterWhen?.md,
      'column--gutter-lg': gutterWhen?.lg,
      'column--gutter-hg': gutterWhen?.hg,
      [`span-${span}`]: span,
      [`span-sm-${spanWhen?.sm}`]: spanWhen?.sm,
      [`span-md-${spanWhen?.md}`]: spanWhen?.md,
      [`span-lg-${spanWhen?.lg}`]: spanWhen?.lg,
      [`span-hg-${spanWhen?.hg}`]: spanWhen?.hg,
      [`shift-${shift}`]: shift,
      [`shift-sm-${shiftWhen?.sm}`]: shiftWhen?.sm,
      [`shift-md-${shiftWhen?.md}`]: shiftWhen?.md,
      [`shift-lg-${shiftWhen?.lg}`]: shiftWhen?.lg,
      [`shift-hg-${shiftWhen?.hg}`]: shiftWhen?.hg,
      [`offset-${offset}`]: offset,
      [`offset-sm-${offsetWhen?.sm}`]: offsetWhen?.sm,
      [`offset-md-${offsetWhen?.md}`]: offsetWhen?.md,
      [`offset-lg-${offsetWhen?.lg}`]: offsetWhen?.lg,
      [`offset-hg-${offsetWhen?.hg}`]: offsetWhen?.hg,
      [`order-${order}`]: order,
      [`order-sm-${orderWhen?.sm}`]: orderWhen?.sm,
      [`order-md-${orderWhen?.md}`]: orderWhen?.md,
      [`order-lg-${orderWhen?.lg}`]: orderWhen?.lg,
      [`order-hg-${orderWhen?.hg}`]: orderWhen?.hg
    })}
  >
    {children}
  </div>
)

Column.propTypes = {
  children: PropTypes.node,
  span: PropTypes.number,
  spanWhen: PropTypes.object,
  shift: PropTypes.oneOf(['m', 'l', 'xl', 'xxl', 'h', 'xh', 'xxh']),
  shiftWhen: PropTypes.object,
  offset: PropTypes.arrayOf(PropTypes.number),
  offsetWhen: PropTypes.object,
  order: PropTypes.number,
  orderWhen: PropTypes.object,
  gutter: PropTypes.bool,
  gutterWhen: PropTypes.object
}

Column.defaultProps = {
  span: 12,
  gutter: true
}

export default Column
