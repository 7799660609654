import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import Check from '../../../assets/icons/check.svg'
import Close from '../../../assets/icons/close.svg'
import Instagram from '../../../assets/icons/instagram.svg'
import PinSolid from '../../../assets/icons/pin-solid.svg'
import Pin from '../../../assets/icons/pin.svg'
import './styles.scss'

const Icon = ({ name, size, sizeWhen, fill, customFill }) => {
  const icons = {
    instagram: <Instagram />,
    close: <Close />,
    check: <Check />,
    pin: <Pin />,
    pinSolid: <PinSolid />
  }

  return (
    <span
      className={cx({
        icon: true,
        'icon--customFill': customFill,
        [`icon--${fill}`]: !customFill && fill,
        [`size-sm`]: sizeWhen.sm,
        [`size-md`]: sizeWhen.md,
        [`size-lg`]: sizeWhen.lg,
        [`size-hg`]: sizeWhen.hg,
        [`size-gt`]: sizeWhen.gt
      })}
      style={{
        '--size': size + 'px',
        '--size-sm': sizeWhen.sm && sizeWhen.sm + 'px',
        '--size-md': sizeWhen.md && sizeWhen.md + 'px',
        '--size-lg': sizeWhen.lg && sizeWhen.lg + 'px',
        '--size-hg': sizeWhen.hg && sizeWhen.hg + 'px',
        '--size-gt': sizeWhen.gt && sizeWhen.gt + 'px',
        '--customFill': customFill && customFill
      }}
    >
      {icons[name]}
    </span>
  )
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sizeWhen: PropTypes.object,
  fill: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quaternary', 'white', 'black']),
  customFill: PropTypes.string
}

Icon.defaultProps = {
  size: 16,
  fill: 'primary',
  sizeWhen: {}
}

export default Icon
