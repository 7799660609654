import React from 'react'
import { types } from 'react-bricks/frontend'
import Column from '../../layouts/Column'
import AngularLogo from '../../../assets/technologies/angular.svg'
import DockerLogo from '../../../assets/technologies/docker.svg'
import GatsbyLogo from '../../../assets/technologies/gatsby.svg'
import KubernetesLogo from '../../../assets/technologies/kubernetes.svg'
import MagnoliaLogo from '../../../assets/technologies/magnolia.svg'
import PwaLogo from '../../../assets/technologies/pwa.svg'
import ReactLogo from '../../../assets/technologies/react-logo.svg'
import SassLogo from '../../../assets/technologies/sass.svg'
import './styles.scss'

/**
 * @type types.Brick<any>
 */
const Technology = ({ technology, index, ...rest }) => {
  const getLogo = technology => {
    const logos = {
      React: <ReactLogo />,
      Angular: <AngularLogo />,
      Sass: <SassLogo />,
      Magnolia: <MagnoliaLogo />,
      Gatsby: <GatsbyLogo />,
      PWA: <PwaLogo />,
      Docker: <DockerLogo />,
      Kubernetes: <KubernetesLogo />
    }
    return logos[technology] ?? null
  }
  return (
    <Column span={6} spanWhen={{ md: 3 }}>
      <div className="technology-stack__item" {...rest}>
        {getLogo(technology)}
      </div>
    </Column>
  )
}

Technology.schema = {
  name: 'technology',
  label: 'Technologie',
  getDefaultProps: () => ({
    name: 'React'
  }),
  hideFromAddMenu: true,
  sideEditProps: [
    {
      name: 'technology',
      label: 'Technologie',
      type: types.SideEditPropType.Select,
      selectOptions: {
        display: types.OptionsDisplay.Radio,
        options: [
          {
            label: 'React',
            value: 'React'
          },
          {
            label: 'Angular',
            value: 'Angular'
          },
          {
            label: 'Sass',
            value: 'Sass'
          },
          {
            label: 'Magnolia',
            value: 'Magnolia'
          },
          {
            label: 'Gatsby',
            value: 'Gatsby'
          },
          {
            label: 'PWA',
            value: 'PWA'
          },
          {
            label: 'Docker',
            value: 'Docker'
          },
          {
            label: 'Kubernetes',
            value: 'Kubernetes'
          }
        ]
      }
    }
  ]
}

export default Technology
