import cx from 'classnames'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import React from 'react'
import './styles.scss'

const AuthorBadge = ({ name, image, border }) => (
  <div className={cx({ 'author-badge': true, 'author-badge--border': border })}>
    {name && (
      <div className="author-badge__name">
        <span>von</span> {name.split(' ')[0]}
        <div className="author-badge__path">
          <svg width="44" height="28" viewBox="0 0 44 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M44 27C24.1832 27.0001 24.5191 1.00002 0 1" />
          </svg>
        </div>
      </div>
    )}
    <div className="author-badge__image">{image}</div>
  </div>
)

AuthorBadge.propTypes = {
  name: PropTypes.string,
  image: PropTypes.element,
  border: PropTypes.bool
}

AuthorBadge.defaultProps = {
  border: false
}

export default AuthorBadge
