import { useLayoutEffect, useRef, useState } from 'react'

const useScrollState = () => {
  const [scrolled, setScrolled] = useState(false)
  const [aboveTheFold, setAboveTheFold] = useState(true)

  const scrolledRef = useRef(scrolled)
  const scrollBuffer = 40

  const _setScrolled = bool => {
    setScrolled(bool)
    scrolledRef.current = bool
  }

  const handleScrollEvent = () => {
    if (scrolledRef.current) {
      window.scrollY <= scrollBuffer && _setScrolled(false)
    } else {
      window.scrollY > scrollBuffer && _setScrolled(true)
    }
    window.scrollY > window.innerHeight ? setAboveTheFold(false) : setAboveTheFold(true)
  }

  useLayoutEffect(() => {
    handleScrollEvent()

    window.addEventListener('scroll', handleScrollEvent, { passive: true })

    return () => window.removeEventListener('scroll', handleScrollEvent, { passive: true })
  }, [])

  return { scrolled, aboveTheFold }
}

export default useScrollState
