import cx from 'classnames'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { simulateClick } from '../../utils/simulateClick'
import './styles.scss'

const PageLink = ({ children, to, state, active, display, navigate, fullWidth }) => {
  const linkElement = useRef(null)

  useEffect(() => {
    navigate && simulateClick(linkElement.current)
  }, [navigate])

  return (
    <div
      className={cx({
        'page-link': true,
        'page-link--fullWidth': fullWidth,
        'page-link--active': active
      })}
      style={{ '--display': display }}
    >
      <Link to={to} ref={linkElement} state={state}>
        {children}
      </Link>
    </div>
  )
}

PageLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  activeStyle: PropTypes.object,
  state: PropTypes.object,
  display: PropTypes.oneOf(['block', 'inline', 'inline-block']),
  navigate: PropTypes.bool,
  fullWidth: PropTypes.bool,
  active: PropTypes.bool
}

PageLink.defaultProps = {
  to: '/',
  variant: 'default',
  display: 'inline-block',
  navigate: false,
  fullWidth: false,
  active: false
}

export default PageLink
