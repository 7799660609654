import cx from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import ArrowLeft from '../../../assets/icons/arrow-left.svg'
import ArrowRight from '../../../assets/icons/arrow-right.svg'
import DesktopMockup from '../../../assets/mockups/imac-mockup.svg'
import TabletMockup from '../../../assets/mockups/ipad-mockup.svg'
import PhoneMockup from '../../../assets/mockups/iphone-red-mockup.svg'
import NotebookMockup from '../../../assets/mockups/macbook-mockup.svg'
import { tween } from '../../animations/transitions'
import { theme } from '../../global/_theme'
import useInterval from '../../hooks/useInterval'
import Flex from '../../layouts/Flex'
import './styles.scss'

const MockupSlider = ({ device, screens, interactiveColor }) => {
  const [index, setIndex] = useState(0)
  const [autoplay, setAutoplay] = useState(true)

  const moveForwards = () => {
    nextScreen()
    setAutoplay(false)
  }

  const moveBackwards = () => {
    previousScreen()
    setAutoplay(false)
  }

  const nextScreen = () => {
    let newIndex

    index < screens.length - 1 ? (newIndex = index + 1) : (newIndex = 0)
    setIndex(newIndex)
  }

  const previousScreen = () => {
    let newIndex

    index === 0 ? (newIndex = screens.length - 1) : (newIndex = index - 1)
    setIndex(newIndex)
  }

  const slideshow = {
    visible: {
      opacity: 1,
      transition: { ...tween('normal', true) }
    },
    hidden: {
      opacity: 0,
      transition: { ...tween('normal', true) }
    }
  }

  useInterval(() => {
    device !== 'iphone' && autoplay && moveForwards()
  }, 8000)

  return (
    <div className="mockup-slider">
      {device !== 'iphone' && screens.length > 1 && (
        <div
          className={cx({
            'mockup-slider__controller': true,
            'mockup-slider__controller--left': true,
            [`mockup-slider__controller--${device}`]: device
          })}
          style={{ '--color': interactiveColor }}
          onClick={moveBackwards}
        >
          <ArrowLeft />
        </div>
      )}
      {device === 'imac' && (
        <div className="mockup-slider__device mockup-slider__device__desktop">
          <AnimatePresence mode="wait">
            <motion.div
              className="mockup-slider__device__screen"
              key={index}
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={slideshow}
            >
              {screens[index]}
            </motion.div>
          </AnimatePresence>
          <DesktopMockup />
        </div>
      )}
      {device === 'macbook' && (
        <div className="mockup-slider__device mockup-slider__device__notebook">
          <AnimatePresence mode="wait">
            <motion.div
              className="mockup-slider__device__screen"
              key={index}
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={slideshow}
            >
              {screens[index]}
            </motion.div>
          </AnimatePresence>
          <NotebookMockup />
        </div>
      )}
      {device === 'ipad' && (
        <div className="mockup-slider__device mockup-slider__device__tablet">
          <AnimatePresence mode="wait">
            <motion.div
              className="mockup-slider__device__screen"
              key={index}
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={slideshow}
            >
              {screens[index]}
            </motion.div>
          </AnimatePresence>
          <TabletMockup />
        </div>
      )}
      {device === 'iphone' && (
        <Flex justify="space-between">
          <div className="mockup-slider__device mockup-slider__device__phone">
            <div className="mockup-slider__device__phone__screen">
              <div className="mockup-slider__device__phone__screen__mask">{screens[0]}</div>
            </div>
            <PhoneMockup />
          </div>
          <div className="mockup-slider__device mockup-slider__device__phone">
            <div className="mockup-slider__device__phone__screen">
              <div className="mockup-slider__device__phone__screen__mask">{screens[1]}</div>
            </div>
            <PhoneMockup />
          </div>
          <div className="mockup-slider__device mockup-slider__device__phone">
            <div className="mockup-slider__device__phone__screen">
              <div className="mockup-slider__device__phone__screen__mask">{screens[2]}</div>
            </div>
            <PhoneMockup />
          </div>
        </Flex>
      )}
      {device !== 'iphone' && screens.length > 1 && (
        <div
          className={cx({
            'mockup-slider__controller': true,
            'mockup-slider__controller--right': true,
            [`mockup-slider__controller--${device}`]: device
          })}
          style={{ '--color': interactiveColor }}
          onClick={moveBackwards}
        >
          <ArrowRight />
        </div>
      )}
    </div>
  )
}

MockupSlider.propTypes = {
  device: PropTypes.oneOf(['iphone', 'ipad', 'macbook', 'imac']),
  screens: PropTypes.arrayOf(PropTypes.element),
  interactiveColor: PropTypes.string
}

MockupSlider.defaultProps = {
  interactiveColor: theme.neutral.white
}

export default MockupSlider
