export const throttle = (callback, limit) => {
  let waiting = false
  let timer

  return args => {
    if (!waiting) {
      callback(args)
      waiting = true
      timer = setTimeout(() => {
        waiting = false
        clearTimeout(timer)
      }, limit)
    }
  }
}
