import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import './styles.scss'

const MobileScrollArea = ({ children, width, wrapped }) => (
  <div
    className={cx({
      'mobile-scroll-area': true,
      'mobile-scroll-area--wrapped': wrapped
    })}
  >
    <div className="mobile-scroll-area__inner" style={{ '--width': width + 'px' }}>
      {children}
    </div>
  </div>
)

MobileScrollArea.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.number,
  childrenWrapper: PropTypes.string,
  wrapped: PropTypes.bool
}

MobileScrollArea.defaultProps = {
  width: '960',
  wrapped: false
}

export default MobileScrollArea
