import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import ArrowIcon from '../../../assets/icons/arrow-right.svg'
import PageLink from '../PageLink'
import './styles.scss'

const Button = ({ variant, kind, ghost, label, to, customColor }) => (
  <>
    {kind === 'internal' && (
      <PageLink to={to}>
        <ButtonInner variant={variant} label={label} ghost={ghost} customColor={customColor} />
      </PageLink>
    )}
    {kind === 'external' && (
      <a href={to} target="_blank" rel="noreferrer" className="button--external">
        <ButtonInner variant={variant} label={label} ghost={ghost} customColor={customColor} />
      </a>
    )}
    {kind === 'contact' && (
      <a href={to} className="button--contact">
        <ButtonInner variant={variant} label={label} ghost={ghost} customColor={customColor} />
      </a>
    )}
  </>
)

const ButtonInner = ({ variant, ghost, customColor, label }) => (
  <button
    className={cx({
      button: true,
      [`button--${variant}`]: variant,
      'button--ghost': ghost
    })}
    style={{ '--customColor': customColor }}
  >
    <span className="button__label">{label}</span>
    <span className="button__arrow">
      <ArrowIcon />
    </span>
  </button>
)

Button.propTypes = {
  variant: PropTypes.string,
  kind: PropTypes.string,
  ghost: PropTypes.bool,
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  external: PropTypes.bool,
  customColor: PropTypes.string
}

Button.defaultProps = {
  variant: 'secondary',
  kind: 'internal',
  ghost: false,
  targetBlank: false,
  shadow: 'soft'
}

ButtonInner.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary', 'custom']),
  kind: PropTypes.oneOf(['internal', 'external', 'contact']),
  ghost: PropTypes.bool,
  label: PropTypes.string.isRequired,
  external: PropTypes.bool,
  shadow: PropTypes.oneOf(['soft', 'base', 'dark']),
  customColor: PropTypes.string
}

export default Button
