import React from 'react'
import Illustration from '../../../assets/illustrations/coffeeBean.svg'
import './styles.scss'

const CoffeeBean = () => (
  <div className="coffee-bean">
    <Illustration />
  </div>
)

export default CoffeeBean
