import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import './styles.scss'

const ScrollIndicator = ({ label, invert }) => {
  const scroll = () => {
    window.scrollTo({ left: 0, top: window.innerHeight * 0.5, behavior: 'smooth' })
  }

  return (
    <div className={cx({ 'scroll-indicator': true, 'scroll-indicator--invert': invert })}>
      <div className="scroll-indicator__link" onClick={scroll}>
        <div className="scroll-indicator__label">{label}</div>
        <div className="scroll-indicator__animation" />
      </div>
    </div>
  )
}

ScrollIndicator.propTypes = {
  label: PropTypes.string.isRequired,
  invert: PropTypes.bool
}

ScrollIndicator.defaultTypes = {
  invert: false
}

export default ScrollIndicator
