import React from 'react'
import Illustration from '../../../assets/illustrations/coffee.svg'
import './styles.scss'

const CoffeeCup = () => (
  <div className="coffee-cup">
    <Illustration />
  </div>
)

export default CoffeeCup
