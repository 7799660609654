import cx from 'classnames'
import { motion, useScroll, useTransform } from 'framer-motion'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { MediaContext } from '../../../context/MediaContext'
import './styles.scss'

const ParallaxEffect = ({ children, speed, enableMobile, axis }) => {
  const { scrollY } = useScroll()
  const { breakpoint } = useContext(MediaContext)

  const elementRef = useRef(null)

  const [enabled, setEnabled] = useState(true)
  const [offsetTop, setOffsetTop] = useState()

  useEffect(() => {
    setOffsetTop(
      Math.round(elementRef.current.getBoundingClientRect().top) -
        elementRef.current.clientHeight / 2 +
        window.pageYOffset
    )
  }, [elementRef.current])

  useEffect(() => {
    setEnabled(enableMobile || breakpoint.lg)
  }, [breakpoint.lg])

  const scrollValue = useTransform(
    scrollY,
    [offsetTop - 1000, offsetTop, offsetTop + 1, offsetTop + 1000],
    [-200, 0, 0, 200],
    [
      {
        clamp: true
      }
    ]
  )

  const y = useTransform(scrollValue, value => (axis === 'y' ? value * speed * -1 : 0))
  const x = useTransform(scrollValue, value => (axis === 'x' ? value * speed : 0))

  return (
    <motion.div
      className={cx({ 'parallax-effect': true, 'parallax-effect--disableMobile': !enableMobile })}
      style={enabled ? { x, y } : { x: 0, y: 0 }}
      ref={elementRef}
    >
      {children}
    </motion.div>
  )
}

ParallaxEffect.propTypes = {
  children: PropTypes.node,
  speed: PropTypes.number,
  enableMobile: PropTypes.bool,
  axis: PropTypes.oneOf(['x', 'y'])
}

ParallaxEffect.defaultProps = {
  speed: 2,
  enableMobile: true,
  axis: 'y'
}

export default ParallaxEffect
