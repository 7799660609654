import React from 'react'
import AmmunitionIllustration from '../../../assets/illustrations/ammunition.svg'
import GunIllustration from '../../../assets/illustrations/nerfgun.svg'
import ParallaxEffect from '../../effects/ParallaxEffect'
import './styles.scss'

const Nerfgun = () => {
  return (
    <div className="nerfgun">
      <div className="nerfgun__gun">
        <GunIllustration />
      </div>
      <div className="nerfgun__ammunition">
        <ParallaxEffect speed={1.5} axis="x">
          <div className="nerfgun__ammunition__item nerfgun__ammunition__item--1">
            <AmmunitionIllustration />
          </div>
        </ParallaxEffect>
        <ParallaxEffect speed={1.5} axis="x">
          <div className="nerfgun__ammunition__item nerfgun__ammunition__item--2">
            <AmmunitionIllustration />
          </div>
        </ParallaxEffect>
        <ParallaxEffect speed={1.5} axis="x">
          <div className="nerfgun__ammunition__item nerfgun__ammunition__item--3">
            <AmmunitionIllustration />
          </div>
        </ParallaxEffect>
      </div>
    </div>
  )
}

export default Nerfgun
