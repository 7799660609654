import React from 'react'
import MobileScrollArea from '../MobileScrollArea'
import './styles.scss'

const IconGrid = ({ children }) => (
  <MobileScrollArea width={900} wrapped>
    <div className="icon-grid">{children}</div>
  </MobileScrollArea>
)

export default IconGrid
