import { useLocation } from '@reach/router'
import PropTypes from 'prop-types'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import useScrollState from '../ui/hooks/useScrollState'

export const PageContext = React.createContext(null)
export const Provider = PageContext.Provider
export const Consumer = PageContext.Consumer

export const PageContextProvider = ({ children }) => {
  const [lang, setLang] = useState('de')
  const [offcanvasMenu, setOffcanvasMenu] = useState(false)
  const [showLogo, setShowLogo] = useState(true)
  const [transformLogo, setTransformLogo] = useState(false)
  const [showMenu, setShowMenu] = useState(true)

  const { pathname } = useLocation()
  const { scrolled } = useScrollState()

  let logoTimer
  let menuTimer

  const handleOffcanvasMenu = () => {
    if (offcanvasMenu) {
      logoTimer = setTimeout(() => setShowLogo(true), 600)
      !scrolled && (menuTimer = setTimeout(() => setShowMenu(true), 600))
      document.querySelector('body').style.overflowY = null
      //document.querySelector('body').style.paddingRight = '0px'
    } else {
      setShowLogo(false)
      !scrolled && setShowMenu(false)
      document.querySelector('body').style.overflowY = 'hidden'
      //document.querySelector('body').style.paddingRight = '15px'
    }
    setOffcanvasMenu(!offcanvasMenu)
  }

  useEffect(() => {
    if (offcanvasMenu) {
      handleOffcanvasMenu()
    }
  }, [pathname])

  useLayoutEffect(() => {
    scrolled ? setShowMenu(false) : setShowMenu(true)
    scrolled ? setTransformLogo(true) : setTransformLogo(false)

    return () => {
      clearTimeout(logoTimer)
      clearTimeout(menuTimer)
    }
  }, [scrolled])

  return (
    <Provider
      value={{
        lang,
        changeLang: newLang => {
          setLang(newLang)
        },
        showLogo,
        changeLogoVisibility: visibility => {
          setShowLogo(visibility)
        },
        transformLogo,
        showMenu,
        changeMenuVisibility: visibility => {
          setShowMenu(visibility)
        },
        offcanvasMenu,
        handleOffcanvasMenu
      }}
    >
      {children}
    </Provider>
  )
}

PageContextProvider.propTypes = {
  children: PropTypes.node
}
