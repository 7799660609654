import cx from 'classnames'
import React from 'react'
import Richtext from '../../components/Richtext'
import '../../global/global.scss'
import Column from '../../layouts/Column'
import Row from '../../layouts/Row'
import Section from '../../layouts/Section'
import './styles.scss'

const SplitContent = ({ children, img, switchOrder, fixedRatio, background }) => {
  const text = (
    <Column span={12} spanWhen={{ lg: 6 }} orderWhen={{ lg: 2 }} key="text" gutter={false}>
      <Richtext>{children}</Richtext>
    </Column>
  )
  const image = (
    <Column span={12} spanWhen={{ lg: 6 }} orderWhen={{ lg: 2 }} key="image" gutter={false}>
      <div className={cx({ 'split-content__image': true, 'split-content__image--fixed-ratio': fixedRatio })}>{img}</div>
    </Column>
  )
  return (
    <Section>
      <Row width="narrow" flex={false}>
        <Column>
          <div
            className={cx({
              'split-content': true,
              [`split-content--bg-${background?.name}`]: background?.name
            })}
          >
            {switchOrder ? [image, text] : [text, image]}
          </div>
        </Column>
      </Row>
    </Section>
  )
}

export default SplitContent
