import React from 'react'
import { Repeater, types } from 'react-bricks/frontend'
import IconGrid from '../../components/IconGrid'
import Column from '../../layouts/Column'
import Row from '../../layouts/Row'
import Section from '../../layouts/Section'

/**
 * @type types.Brick<any>
 */
const IconGallery = ({}) => {
  return (
    <Section>
      <Row width="content" flex={false}>
        <Column>
          <IconGrid>
            <Repeater propName="iconItems" />
          </IconGrid>
        </Column>
      </Row>
    </Section>
  )
}

IconGallery.schema = {
  name: 'iconGallery',
  label: 'Icon Gallery',
  repeaterItems: [
    {
      name: 'iconItems',
      itemType: 'iconGalleryItem',
      itemLabel: 'Item',
      min: 2
    }
  ],
  sideEditProps: []
}

export default IconGallery
