import React from 'react'
import { Repeater, types } from 'react-bricks/frontend'
import BenefitsGrid from '../../components/BenefitsGrid'

/**
 * @type types.Brick<any>
 */
const BenefitsGallery = ({}) => {
  return (
    <BenefitsGrid>
      <Repeater propName="benefits" />
    </BenefitsGrid>
  )
}

BenefitsGallery.schema = {
  name: 'benefitsGallery',
  label: 'Benefits',
  repeaterItems: [
    {
      name: 'benefits',
      itemType: 'benefitsItem',
      itemLabel: 'Benefit Item',
      min: 2
    }
  ]
}

export default BenefitsGallery
