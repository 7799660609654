import { theme } from '../global/_theme'

export const tween = (speed, easing, accelerated) => {
  const mapper = {
    fast: theme.duration.raw.fast,
    normal: theme.duration.raw.normal,
    sluggish: theme.duration.raw.sluggish,
    slow: theme.duration.raw.slow
  }

  return {
    type: 'tween',
    duration: mapper[speed] ? mapper[speed] : mapper.normal,
    ease: easing && accelerated ? theme.easing.array.accelerated : theme.easing.array.default
  }
}
