import { RichText as RbRichtext, types, usePageValues } from 'react-bricks/frontend'

import Column from '../layouts/Column'
import React from 'react'
import Richtext from '../components/Richtext'
import Row from '../layouts/Row'
import Section from '../layouts/Section'

/**
 * @type types.Brick<any>
 */
const RichtextContent = ({ paddingTop, paddingBottom }) => {
  const [page] = usePageValues()
  const rowWidth = page.type === 'blogpost' || page.type === 'job' ? 'narrow' : 'content'
  const distanceDirection = paddingBottom ? (paddingTop ? 'both' : 'bottom') : 'top'

  return (
    <Section distance={paddingTop || paddingBottom ? 'small' : undefined} distanceDirection={distanceDirection}>
      <Row width={rowWidth} flex={false}>
        <Column>
          <Richtext invert={page.type === 'case'} variant={page.type === 'job' ? 'small' : 'default'}>
            <RbRichtext
              propName="richtext"
              placeholder="Richtext..."
              renderBlock={({ children }) => <p>{children}</p>}
              allowedFeatures={getRichtextFeatures(page.type)}
            />
          </Richtext>
        </Column>
      </Row>
    </Section>
  )

  function getRichtextFeatures(pagetype) {
    return (
      {
        blogpost: [
          types.RichTextFeatures.Link,
          types.RichTextFeatures.UnorderedList,
          types.RichTextFeatures.OrderedList,
          types.RichTextFeatures.Heading3,
          types.RichTextFeatures.Italic
        ],
        case: [types.RichTextFeatures.Heading3],
        job: [
          types.RichTextFeatures.Heading3,
          types.RichTextFeatures.Link,
          types.RichTextFeatures.UnorderedList,
          types.RichTextFeatures.Bold
        ]
      }[pagetype] ?? []
    )
  }
}

RichtextContent.schema = {
  name: 'richtextContent',
  label: 'Richtext',
  getDefaultProps: () => ({
    richtext: 'Text here...',
    paddingTop: false,
    paddingBottom: false
  }),
  sideEditProps: [
    {
      name: 'paddingTop',
      label: 'Mehr Abstand nach oben',
      type: types.SideEditPropType.Boolean
    },
    {
      name: 'paddingBottom',
      label: 'Mehr Abstand nach unten',
      type: types.SideEditPropType.Boolean
    }
  ]
}

export default RichtextContent
