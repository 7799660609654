import React, { useState } from 'react'
import Banner from '../../layouts/Banner'
import Column from '../../layouts/Column'
import Row from '../../layouts/Row'
import Spacer from '../../layouts/Spacer'
import Heading from '../Heading'
import { BenefitsContext } from './benefits-context'
import './styles.scss'

const BenefitsGrid = ({ children }) => {
  const [selected, setSelected] = useState(-1)
  const [freeForEntering, setFreeForEntering] = useState(true)

  function changeSelected(index) {
    if (selected === index) {
      setSelected(-1)
    } else {
      setSelected(index)
      if (selected !== -1) {
        setFreeForEntering(false)
      }
    }
  }

  return (
    <Banner variant="light">
      <Row width="large">
        <Column>
          <Heading level={2} center>
            So lässt es sich arbeiten ...
          </Heading>
        </Column>
      </Row>
      <Spacer size="xs" when={{ lg: 'xl' }} />
      <Row width="medium" flex={false}>
        <Column>
          <BenefitsContext.Provider value={{ selected, changeSelected, freeForEntering, setFreeForEntering }}>
            <ul className="benefits-grid">{children}</ul>
          </BenefitsContext.Provider>
        </Column>
      </Row>
      <Spacer size="xxl" when={{ lg: 'z' }} />
    </Banner>
  )
}

export default BenefitsGrid
