import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import Heading from '../Heading'
import './styles.scss'

const Interview = ({ question, children }) => (
  <div className="interview">
    <Heading level={2}>{question}</Heading>
    <div className="interview__answer">{children}</div>
  </div>
)

Interview.propTypes = {
  question: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  children: PropTypes.node.isRequired
}

export default Interview
