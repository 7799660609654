import React from 'react'
import { Image, Text, types } from 'react-bricks/frontend'
import IconGridItem from '../../components/IconGridItem'

/**
 * @type types.Brick<any>
 */
const IconGalleryItem = ({ text, title, icon, ...rest }) => {
  return (
    <IconGridItem
      text={<Text propName="text" placeholder="Text..." renderBlock={props => <>{props.children}</>} />}
      title={<Text propName="title" placeholder="Title..." renderBlock={props => <>{props.children}</>} />}
      icon={<Image propName="icon" alt="Piktogramm" aspectRatio={1} />}
      {...rest}
    />
  )
}

IconGalleryItem.schema = {
  name: 'iconGalleryItem',
  label: 'Icon Gallery Item',
  getDefaultProps: () => ({
    text: 'Text',
    title: 'Titel'
  }),
  hideFromAddMenu: true
}

export default IconGalleryItem
