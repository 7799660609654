import React from 'react'
import { types, usePageValues } from 'react-bricks/frontend'
import MockupSlider from '../../components/MockupSlider'
import '../../global/global.scss'
import Column from '../../layouts/Column'
import Row from '../../layouts/Row'
import Section from '../../layouts/Section'

/**
 * @type types.Brick<any>
 */
const MockupSliderIphone = ({ image1, image2, image3 }) => {
  const [page] = usePageValues()
  return (
    <Section distance="tiny">
      <Row width="content">
        <Column>
          <MockupSlider
            device="iphone"
            interactiveColor={page.customValues?.accentColor}
            screens={[image1, image2, image3]
              .filter(image => image)
              .map(image => (
                <img
                  key={image.src}
                  src={image.src ?? ''}
                  className="fit-image"
                  alt={image.alt ?? ''}
                  srcSet={image.srcSet ?? ''}
                />
              ))}
          />
        </Column>
      </Row>
    </Section>
  )
}

const imageOptions = {
  maxWidth: 640,
  aspectRatio: 0.4665
}

MockupSliderIphone.schema = {
  name: 'iphoneSlider',
  label: 'Mockup Slider - iPhone',
  getDefaultProps: () => ({ count: 3 }),
  sideEditProps: [
    {
      type: types.SideEditPropType.Image,
      name: 'image1',
      imageOptions
    },
    {
      type: types.SideEditPropType.Image,
      name: 'image2',
      imageOptions
    },
    {
      type: types.SideEditPropType.Image,
      name: 'image3',
      imageOptions
    }
  ]
}

export default MockupSliderIphone
