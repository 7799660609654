import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import './styles.scss'

const Prolog = ({ children, invert }) => (
  <div className={cx({ prolog: true, 'prolog--invert': invert })}>{children}</div>
)

Prolog.propTypes = {
  children: PropTypes.node,
  invert: PropTypes.bool
}

export default Prolog
