/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react'
import SiteWrapper from './src/ui/components/SiteWrapper'
import { theme } from './src/ui/global/_theme'
import './static/fonts.css'

export const wrapPageElement = ({ element, props }) => {
  return <SiteWrapper {...props}>{element}</SiteWrapper>
}

export const onInitialClientRender = () => {
  document.documentElement.setAttribute('data-initial-render', true)
  if (history.scrollRestoration) history.scrollRestoration = 'manual'
}

export const onRouteUpdate = () => {
  document.documentElement.setAttribute('data-initial-render', false)
}

export const shouldUpdateScroll = ({ routerProps, getSavedScrollPosition }) => {
  const TRANSITION_DELAY = theme.duration.raw.pageTransition * 1000
  // if it's a "normal" route
  if (routerProps.location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), TRANSITION_DELAY)
  }
  // if we used the browser's forwards or back button
  else {
    const savedPosition = getSavedScrollPosition(routerProps.location) || [0, 0]
    window.setTimeout(() => window.scrollTo(...savedPosition), TRANSITION_DELAY)
  }
  return false
}
